<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <h2 v-if="storegiftlist.usuario">{{ storegiftlist.usuario.usu_nome }}</h2>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'store-gift-lists'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th>Data do Casamento:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_data_casamento }}</td>
                <th>Email:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_nvo_email }}</td>
            </tr>
            <tr>
                <th>Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nvo_nome }}</td>
                <th>Pai do Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nvo_pai }}</td>
                <th>Mãe do Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nvo_mae }}</td>
            </tr>
            <tr>
                <th>Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nva_nome }}</td>
                <th>Pai do Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nva_pai }}</td>
                <th>Mãe do Noivo:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nva_mae }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DA IGREJA</td>
            </tr>
            <tr>
                <th>Igreja:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_igreja }}</td>
                <th>Bairro:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_bairro_igreja }}</td>
                <th>Complemento:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_compl_igreja }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DA FESTA</td>
            </tr>
            <tr>
                <th>Local da Festa:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_local_festa }}</td>
                <th>Bairro:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_bairro_festa }}</td>
                <th>Complemento:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_compl_festa }}</td>
            </tr>
            <tr>
                <th>Informações da Festa:</th>
                <td colspan="8">{{ storegiftlist.stor_gf_mais_info_festa }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DA ENTREGA ANTES DO CASAMENTO</td>
            </tr>
            <tr>
                <th>Nome:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nome_entrega_antes }}</td>
                <th>Endereço:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_endereco_antes }}, {{ storegiftlist.stor_gf_num_antes }}</td>
                <th>CEP:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_cep_antes }}</td>
            </tr>
            <tr>
                <th>Bairro:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_bairro_antes }}</td>
                <th>Cidade:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_cidade_antes }}</td>
                <th>Estado:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_uf_antes }}</td>
            </tr>
            <tr>
                <th>Telefone:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_fone_antes }}</td>
                <th>Celular:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_celular_antes }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DA ENTREGA DEPOIS DO CASAMENTO</td>
            </tr>
            <tr>
                <th>Nome:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_nome_entrega_pos }}</td>
                <th>Endereço:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_endereco_pos }}, {{ storegiftlist.stor_gf_num_pos }}</td>
                <th>CEP:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_cep_pos }}</td>
            </tr>
            <tr>
                <th>Bairro:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_bairro_pos }}</td>
                <th>Cidade:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_cidade_pos }}</td>
                <th>Estado:</th>
                <td colspan="2">{{ storegiftlist.stor_gf_uf_pos }}</td>
            </tr>
            <tr>
                <th>Telefone:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_fone_pos }}</td>
                <th>Celular:</th>
                <td colspan="3">{{ storegiftlist.stor_gf_celular_pos }}</td>
            </tr>
            <tr>
                <th>Status:</th>
                <td colspan="3">{{ (storegiftlist.stor_gf_status == '1' ) ? 'ATIVO' : 'INATIVO' }}</td>
                <th>Finalizada:</th>
                <td colspan="3">{{ (storegiftlist.stor_gf_finalizada == '1' ) ? 'SIM' : 'NÃO' }}</td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailStoreGiftListComponent",
    created() {
        this.loadStoreGiftList()
    },
    props: {
        stor_gf_id: {
            require: true
        }
    },
    data() {
        return {
            storegiftlist: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadStoreGiftList() {
            this.$store.dispatch('loadStoreGiftList', this.stor_gf_id)
                .then(response => {
                    this.storegiftlist = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.table th.texto {
    vertical-align: top;
}
</style>
